<template>
  <v-container class="fill-height"
               fluid>
    <v-row align="center">
      <v-col v-if="!isMobile"
             cols="6">
        <v-img src="@/assets/ess-logo-text.png"
               alt="Logo"
               class="mx-auto"
               max-width="400px" />
      </v-col>
      <v-col :cols="isMobile ? 12 : 6">
        <ValidationObserver ref="obs"
                            v-slot="{invalid, passes}">
          <v-alert v-if="envMessage"
                   :color="getEnvColor()"
                   dark
                   class="elevation-5"
                   :class="{'mx-auto': isMobile}"
                   max-width="650px">
            This application is running in {{ environment }} Mode
          </v-alert>
          <ess-card title="Sign In"
                    max-width="650px"
                    :class="{'mx-auto': isMobile}">
            <v-card-text>
              <v-row>
                <v-col v-if="isMobile"
                       cols="12">
                  <v-row align="center"
                         justify="center">
                    <v-img src="@/assets/logo-240x240.png"
                           alt="Logo"
                           class="mx-auto"
                           max-width="240px" />
                  </v-row>
                </v-col>
                <v-col class="pa-5">
                  <v-form>
                    <ess-text-field v-model="user.email"
                                    :disabled="$wait.any"
                                    label="Email"
                                    prepend-inner-icon="mdi-account"
                                    type="text"
                                    rules="required|email"
                                    @enter="passes(loginUser)" />

                    <ess-text-field v-model="user.password"
                                    :disabled="$wait.any"
                                    label="Password"
                                    prepend-inner-icon="mdi-lock"
                                    type="password"
                                    rules="required"
                                    @enter="passes(loginUser)" />
                  </v-form>
                </v-col>
              </v-row>
            </v-card-text>

            <template slot="actions">
              <span class="primary--text">Version: {{ version }}</span>
              <v-spacer />
              <v-btn color="success"
                     :disabled="invalid"
                     :loading="$wait.is('loginLoading')"
                     @click="passes(loginUser)">
                Login
              </v-btn>
            </template>
          </ess-card>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';

import { USER_ROLES } from '@/config/constants';

export default {
  components: {
    ValidationObserver,
  },

  data() {
    return {
      user: {
        email: '',
        password: '',
      },
      environment: process.env.VUE_APP_ENV,
      version: process.env.VUE_APP_VERSION,
    };
  },

  computed: {
    envMessage() {
      return this.environment !== 'Production';
    },
  },

  created() {
    const currentUser = this.$store.getters['session/user'];
    if (currentUser) {
      switch (currentUser.role) {
        case USER_ROLES.COACH:
          this.$router.push({ name: 'CoachDashboard' });
          break;
        case USER_ROLES.ATHLETE:
          this.$router.push({ name: 'AthleteDashboard' });
          break;
        default:
          this.$router.push('/logout');
          break;
      }
    }
  },

  methods: {
    ...mapActions('session', ['login']),

    ...mapWaitingActions('session', {
      login: 'loginLoading',
    }),

    loginUser() {
      this.login(this.user);
    },

    getEnvColor() {
      let color;

      switch (this.environment) {
        case 'Development':
          color = 'secondary';
          break;
        case 'Testing':
          color = 'warning';
          break;
        case 'Staging':
          color = 'deep-purple darken-1';
          break;
        default:
          color = 'Not set';
          break;
      }

      return color;
    },
  },
};
</script>
